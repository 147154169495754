import store from '@/store'
import router from '@/router'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { foundryRequest } from '@/config/authConfig';
import { unparse, parse } from "papaparse";
import axios from 'axios'
import { transformRequestOptions } from '@core/utils'


export default function useSurvey() {

    const vm = getCurrentInstance().proxy
    const surveyTemplate = ref({})

    const projectData = ref({})
    const choicesOrder = ref([
        {
          text: "None",
          value: null
        },
        {
          text: "Random",
          value: "random"
        },
        {
          text: "Alph. Ascending",
          value: "asc"
        },
        {
          text: "Alph. Descending",
          value: "desc"
        }
    ])


    const elementOptions = ref([])
    const elementLoading = ref(false)

    const languageOptions = ref([])
    const languageLoading = ref(false)
    const questionnaireCategoryOptions = ref([])
    const questionnaireCategoryLoading = ref(false)
    const questionnaireResearchMethodOptions = ref([])
    const questionnaireResearchMethodLoading = ref(false)
    const deleteQuestionnaireLoading = ref(false)

    const translationLoading = ref(false)

    const exportLoading = ref(false)
    const dlVersionLoading = ref(false)
    const dlWordVersionLoading = ref(false)

    const questionnaireOptions = ref([])
    const questionnaireLoading = ref(false)
    
    const templateOptions = ref([])
    const templateLoading = ref(false)
    
    
    const versionsItems = ref([])
    const versionsLoading = ref(false)
    
    const variableSuggestions = ref([
      {id:1, label:"testProduct"},
      {id:2, label:"competitor"},
      {id:3, label:"brand"},
      {id:4, label:"testCat"},
      {id:5, label:"usageTime"},
      {id:6, label:"clutterCat1"},
      {id:7, label:"clutterCat2"},
      {id:8, label:"clutterCat3"},
      {id:9, label:"clutterCat4"},
      {id:10, label:"price"}
    ])

      
    const csvDelimiter = ","
    const newLineDelimiter = "\n";

    // fetch data

    
    const fetchElements = async (queryParams) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      elementLoading.value = true
      return store
      .dispatch('app-survey/fetchElements', queryParams)
      .then(response => {
          const { data } = response

          elementOptions.value = data
          elementOptions.value.unshift({
            type: "previous",
            name: "Previous",
            id:0
        })
          elementLoading.value = false

      })
      .catch(error => {
          console.log(error)
      })
    }
    
    const fetchVersions = async (queryParams) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      versionsLoading.value = true
      return store
      .dispatch('app-survey/fetchVersions', queryParams)
      .then(response => {
        const { data } = response

          versionsItems.value = data
          
          versionsLoading.value = false

      })
      .catch(error => {
          console.log(error)
      })
    }




    const fetchProject = async () => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)

        let params = {}
        
        if (router.currentRoute.params.id) params['id'] = router.currentRoute.params.id
        if (router.currentRoute.params.country) params['country'] = router.currentRoute.params.country
        if (router.currentRoute.params.templateId) params['templateId'] = router.currentRoute.params.templateId

        
        return store
        .dispatch('app-survey/fetchProject', params)
        .then(response => {
            const { data } = response

            projectData.value = data

        })
        .catch(error => {
            console.log(error)
            router.push({
            name: 'misc-error'
            })
        })
    }

    const addProjectQuestionnaire = async (payload) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      
      store
      .dispatch('app-survey/addProjectQuestionnaire', payload)
      .then(response => {
          const { data } = response

        router.push({name: 'survey-project-view', params: {id: payload.job_number, country: payload.country, new:true}})

      })
      .catch(error => {
          console.log(error)
          router.push({
          name: 'misc-error'
          })
      })
  }

    // fetch data
    const fetchQuestionnaires = async (queryParams) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      questionnaireLoading.value = true
      store
      .dispatch('app-survey/fetchQuestionnaires', queryParams)
      .then(response => {
          const { data } = response

          questionnaireOptions.value = flattenData(data).flat(3)
          questionnaireLoading.value = false

      })
      .catch(error => {
          console.log(error)
          router.push({
          name: 'misc-error'
          })
      })
    }
    const flattenData = (data) => {
      return data.map(item => {
        return item.json.pages.map(section => {
          if ('elements' in section)
            return section.elements.map(element => {
              if(element.name === undefined) element['name'] = element.valueName
              element['job'] = `${item.job_number} - ${item.job_name} - ${item.country}`
              element['section'] = section.name
              element['id'] = `${item.job_number}_${item.country}_${element.name}`
              element['sectionDesc'] = section.description
              element['sectionTitle'] = section.title
              return element
              
            })
          return []
        })
      })
    }


    const fetchLanguages = async () => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      languageLoading.value = true
        store
        .dispatch('app-survey/fetchLanguages')
        .then(response => {
            const { data } = response

            languageOptions.value = data
            languageLoading.value = false

        })
        .catch(error => {
            console.log(error)
            router.push({
            name: 'misc-error'
            })
        })
    }
    
    const fetchTemplates = async () => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      templateLoading.value = true
        store
        .dispatch('app-survey/fetchTemplates')
        .then(response => {
            const { data } = response

            templateOptions.value = data
            templateLoading.value = false

        })
        .catch(error => {
            console.log(error)
            router.push({
            name: 'misc-error'
            })
        })
    }
    
    
    const fetchTemplate = async (id) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      templateLoading.value = true
        return store
        .dispatch('app-survey/fetchTemplate', id)
        .then(response => {
            const { data } = response

            surveyTemplate.value = data
            templateLoading.value = false

        })
        .catch(error => {
            console.log(error)
            router.push({
            name: 'misc-error'
            })
        })
    }
    
    const fetchQuestionnaireCategories = async (id) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      questionnaireCategoryLoading.value = true
        return store
        .dispatch('app-survey/fetchQuestionnaireCategories')
        .then(response => {
            const { data } = response

            questionnaireCategoryOptions.value = data
            questionnaireCategoryLoading.value = false

        })
        .catch(error => {
            console.log(error)
            router.push({
            name: 'misc-error'
            })
        })
    }

    
    const fetchQuestionnaireResearchMethods = async (id) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      questionnaireResearchMethodLoading.value = true
        return store
        .dispatch('app-survey/fetchQuestionnaireResearchMethods')
        .then(response => {
            const { data } = response

            questionnaireResearchMethodOptions.value = data
            questionnaireResearchMethodLoading.value = false

        })
        .catch(error => {
            console.log(error)
            router.push({
            name: 'misc-error'
            })
        })
    }

    const addQuestionnaireVersion = async (payload, versions=[]) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      
      store
      .dispatch('app-survey/addQuestionnaireVersion', payload)
      .then(response => {
          const { data } = response
          versions.push(data)

      })
      .catch(error => {
          console.log(error)
          router.push({
          name: 'misc-error'
          })
      })
  }

    const addQuestionnaireCategory = async (payload) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      
      store
      .dispatch('app-survey/addQuestionnaireCategory', payload)
      .then(response => {
          const { data } = response

      })
      .catch(error => {
          console.log(error)
          router.push({
          name: 'misc-error'
          })
      })
  }
  
  const deleteQuestionnaire = async (id) => {
    const token = await vm.$msal.getTokenPopup(foundryRequest)
    deleteQuestionnaireLoading.value = true
    store
    .dispatch('app-survey/deleteQuestionnaire', id)
    .then(response => {
        const { data } = response
        deleteQuestionnaireLoading.value = false
        router.push({
          name: 'survey-view'
          })
    })
    .catch(error => {
        console.log(error)
        router.push({
        name: 'misc-error'
        })
    })
}

    const updateQuestionnaire = async (questionnaire) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      
      store
      .dispatch('app-survey/updateQuestionnaire', questionnaire)
      .then(response => {
          const { data } = response
          // questionnaire.json = data.json
          fetchProject()
      })
      .catch(error => {
          console.log(error)
          router.push({
          name: 'misc-error'
          })
      })
  }

    const downloadQuestionnaire = async (questionnaire) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        exportLoading.value = true
        store
        .dispatch('app-survey/downloadQuestionnaire', {
          questionnaire
        })
        .then(response => {
            const { data } = response
            exportLoading.value = false
            const disposition = response.headers['content-disposition'];
            const filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
            
            let fileURL = window.URL.createObjectURL(new Blob([data]));
            let fileLink = document.createElement('a');
        
            fileLink.href = fileURL;
            fileLink.setAttribute('download', filename);
            document.body.appendChild(fileLink);
        
            fileLink.click();

        })
        .catch(error => {
          // exportLoading.value = false
          // store.commit('app/LOADING', false)
          console.log(error.response)
          store.commit('comp-alert/SET_MESSAGE', error.response.statusText)
        })
    }
    const downloadWordQuestionnaireVersion = async (id) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      dlWordVersionLoading.value = true
      store
      .dispatch('app-survey/downloadWordQuestionnaireVersion', 
        id
      )
      .then(response => {
          const { data } = response
          dlWordVersionLoading.value = false
          const disposition = response.headers['content-disposition'];
          const filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
          
          let fileURL = window.URL.createObjectURL(new Blob([data]));
          let fileLink = document.createElement('a');
      
          fileLink.href = fileURL;
          fileLink.setAttribute('download', filename);
          document.body.appendChild(fileLink);
      
          fileLink.click();

      })
      .catch(error => {
        // exportLoading.value = false
        // store.commit('app/LOADING', false)
        console.log(error.response)
        store.commit('comp-alert/SET_MESSAGE', error.response.statusText)
      })
  }
    const downloadQuestionnaireVersion = async (id) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      dlVersionLoading.value = true
      store
      .dispatch('app-survey/downloadQuestionnaireVersion', 
        id
      )
      .then(response => {
          const { data } = response
          dlVersionLoading.value = false
          const disposition = response.headers['content-disposition'];
          const filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
          
          let fileURL = window.URL.createObjectURL(new Blob([data]));
          let fileLink = document.createElement('a');
      
          fileLink.href = fileURL;
          fileLink.setAttribute('download', filename);
          document.body.appendChild(fileLink);
      
          fileLink.click();

      })
      .catch(error => {
        // exportLoading.value = false
        // store.commit('app/LOADING', false)
        console.log(error.response)
        store.commit('comp-alert/SET_MESSAGE', error.response.statusText)
      })
  }


    const allEqual = arr => arr.every( v => v === arr[0] )

    const getContentTooltip = (text, content, locale) => {
        var tooltip = ""
        if (content && text){
          Object.keys(content).forEach(key => {
            
            const regex = new RegExp(`\{${key}\}`)
            var contentValue = content[key]
            if (typeof contentValue === 'array' && allEqual(contentValue)) contentValue = contentValue[0]
            if (typeof contentValue === 'object' && contentValue['translation'] != undefined) contentValue = contentValue['translation'][locale]
            if (typeof text === 'object' && text['name'] == undefined) text = text[locale]
            if (regex.test(text)) {
              tooltip += `${key} = ${contentValue}\n`
            }
          })
        }
        
        return tooltip
    }

    const textMapping = {
      title: "Title",
      description: "Description",
      text: "Text",
      notes: "Notes",
      int_notes: "Int/Rec Instructions",
      noneText: "None option text",
      otherText: "Other option text",
      html: "HTML Markup",
      theme: "Theme"
    }

    const fillItemsHash = (parentName, group, itemsHash) => {
      let name = ""
      if (group.name) name = parentName ? parentName + "." + group.name : group.name
      if (group.name == undefined) name = parentName


      
      Object.keys(textMapping).forEach(key => {
        if (group[key]) {
          itemsHash[name + "." + key] = group[key];
        }
      });
      // group.locItems.forEach((item) => {
      //   itemsHash[name + "." + item.name] = item;
      // });
      if (group['choices']) group['choices'].forEach((group) => {
        if (group.value != undefined) return fillItemsHash(`${name}.choices.${group.value}`, group, itemsHash)
        else return fillItemsHash(`${name}.choices`, group, itemsHash)
      });
      if (group['columns']) group['columns'].forEach((group) => {
        if (group.value != undefined) return fillItemsHash(`${name}.columns.${group.value}`, group, itemsHash)
        else return fillItemsHash(`${name}.columns`, group, itemsHash)
      });
      if (group['rows']) group['rows'].forEach((group) => {
        if (group.value != undefined) return fillItemsHash(`${name}.rows.${group.value}`, group, itemsHash)
        else return fillItemsHash(`${name}.rows`, group, itemsHash)
      });
      
      if (group['themes']) group['themes'].forEach((group, idx) => {
        return fillItemsHash(`${name}.themes.${idx}`, group, itemsHash)
      });
      if (group['elements']) group['elements'].forEach((group) => fillItemsHash(name, group, itemsHash));
    }

    const updateItemWithStrings = ( item, values, locales ) => {
      for (let i = 0; i < values.length && i < locales.length; i++) {
        let val = values[i].trim();
        //Check if there is already a translation (commented for now as we want to update everything)
        if (!val) continue;
        item[locales[i]] = val
        
        
      }
    }

    const importFromNestedArray = (root, rows) => {
      let locales = rows.shift().slice(1);
      let itemsHash = {};
      
      root.forEach(group => fillItemsHash("", group, itemsHash))
      rows.forEach((row) => {
        let name = row.shift().trim();
        if (!name) return;
        let item = itemsHash[name];
        if (!item) return;
        updateItemWithStrings(item, row, locales);
      });

      // this.reset();
      // if (this.importFinishedCallback) this.importFinishedCallback();
    }

    const exportToCSV = (root, locales) => {
      let res = [];
      let headerRow = [];
      headerRow.push("description ↓ - language →");
      for (let i = 0; i < locales.length; i++) {
        headerRow.push(!!locales[i] ? locales[i] : "en");
      }
      res.push(headerRow);
      var itemsHash = {};
      root.forEach(group => fillItemsHash("", group, itemsHash))
      
      for (let key in itemsHash) {
        let row = [key];
        let item = itemsHash[key];
        for (let i = 0; i < locales.length; i++) {
          let val = item[locales[i]];
          row.push(val);
        }
        res.push(row);
      }
      let prefix = "\uFEFF";
      return (
        prefix +
        unparse(res, {
          quoteChar: '"',
          escapeChar: '"',
          delimiter: csvDelimiter,
          header: true,
          newline: newLineDelimiter,
          skipEmptyLines: false, //or 'greedy',
          columns: null, //or array of strings
        })
      );
    }
    

    const exportToCSVFile = (fileName, root, locales) => {
      var data = exportToCSV(root, locales)
      var blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      if (window.navigator["msSaveOrOpenBlob"]) {
      window.navigator["msSaveBlob"](blob, fileName);
      } else {
      var elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(blob);
      elem.download = fileName;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
      }
    }
    const importFromCSVFile = (root, file) => {
      parse(file, {
        complete: (results, file) => {
          importFromNestedArray(root, results.data);
        },
      });
    }

    const translateViaAI = async (project, defaultLocale, locale, translateAll=true) => {
      var itemsHash = {};
      var root = project.json.pages
      root.forEach(group => fillItemsHash("", group, itemsHash))
      var res = {}
      var dataToTranslate = []
      for (let key in itemsHash) {
        if (translateAll) {

          if (itemsHash[key][defaultLocale] !== undefined){

            res[key] = itemsHash[key][defaultLocale]
            dataToTranslate.push({text: itemsHash[key][defaultLocale]})
          }
        } else {
          if (itemsHash[key][defaultLocale] !== undefined && itemsHash[key][locale] === undefined){
  
            res[key] = itemsHash[key][defaultLocale]
            dataToTranslate.push({text: itemsHash[key][defaultLocale]})
          }
        }
      }
      console.log(res)
      const token = await vm.$msal.getTokenPopup(foundryRequest)

      const payload = {
        language: defaultLocale,
        project: project.job_number,
        market: project.country,
        locale: locale,
        bot: 2,
        original_json: res
      }
      
      translationLoading.value = true

      store
      .dispatch('app-survey/translateQuestionnaire', payload)
      .then(response => {
          const { data } = response
          var translatedJson = JSON.parse(JSON.stringify(data['translated_json']))
          Object.entries(translatedJson).forEach((entry) => {
            const [key, value] = entry
            let item = itemsHash[key];
            if (!item) return;
            const row = [value, '']
            translationLoading.value = false
            updateItemWithStrings(item, row, [locale]);
          })
          updateQuestionnaire(project)

      })
      .catch(error => {
          console.log(error)
          router.push({
          name: 'misc-error'
          })
      })
      


    }
    const getRanHex = size => {
      let result = [];
      let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
    
      for (let n = 0; n < size; n++) {
        result.push(hexRef[Math.floor(Math.random() * 16)]);
      }
      return result.join('');
    }

    const UUIDGenerator = () => {
      return getRanHex(16)
    }

    const replaceAll = (str,mapObj) => {
      var re = new RegExp(Object.keys(mapObj).join("|"),"gi");
      return str.replace(re, function(matched){
          return mapObj[matched];
      });
  }

    const translateWordViaAzureTranslator = async (word, from, to) => {

      const wordToTranslation = [{
        text: word
      }]

      const res = await axios({
        baseURL: process.env.VUE_APP_TRANSLATOR_BASE_URL,
        url: "/translate",
        method: "post",
        headers: {
          "Ocp-Apim-Subscription-Key": process.env.VUE_APP_TRANSLATOR_API_KEY,
          "Ocp-Apim-Subscription-Region": process.env.VUE_APP_TRANSLATOR_REGION,
          "Content-type": "application/json",
          "X-ClientTraceId": UUIDGenerator()
        },
        params: {
          "api-version": "3.0",
          "from": from,
          "to": to,
          "textType": "html"
        },
        paramsSerializer: params => transformRequestOptions(params),
        data: wordToTranslation,
        responseType: "json"
      }).catch(function (error) {
        console.log("Error to translate: " + to + ". Error: " + JSON.stringify(error, null, 2));
      });

      return await res.data
    }

    const translateViaAzureTranslator = (project, defaultLocale, locale, translateAll=true) => {

      let keys = {}
      Object.keys(project.content).forEach(key => {
        keys[`{${key}}`] = `<div class="notranslate">${key}</div>`
        keys[`<div class="notranslate">${key}</div>`] = `{${key}}`
      })


      var itemsHash = {};
      var categoryElements = [
        {
          name: "testCat",
          description: project.content.testCat.translation
        },
        {
          name: "clutterCat1",
          description: project.content.clutterCat1.translation
        },
        {
          name: "clutterCat2",
          description: project.content.clutterCat2.translation
        },
        {
          name: "clutterCat3",
          description: project.content.clutterCat3.translation
        },
        {
          name: "clutterCat4",
          description: project.content.clutterCat4.translation
        }
      ]
     
      var root = project.json.pages
      root.push({
        elements: categoryElements,
        name: "Categories"
      })
      root.forEach(group => fillItemsHash("", group, itemsHash))
      var res = {}
      var dataToTranslate = []
      for (const key of Object.keys(itemsHash)) {
        if (translateAll) {

          if (itemsHash[key][defaultLocale] !== undefined){

            res[key] = itemsHash[key][defaultLocale]
            dataToTranslate.push({text: replaceAll(itemsHash[key][defaultLocale], keys)})
          }
        } else {
          if (itemsHash[key][defaultLocale] !== undefined && (itemsHash[key][locale] === undefined || !itemsHash[key][locale])){
            res[key] = itemsHash[key][defaultLocale]
            dataToTranslate.push({text: replaceAll(itemsHash[key][defaultLocale], keys)})
          }
        }
      }
      //Remove the category element
      project.json.pages.pop()
      axios({
        baseURL: process.env.VUE_APP_TRANSLATOR_BASE_URL,
        url: "/translate",
        method: "post",
        headers: {
          "Ocp-Apim-Subscription-Key": process.env.VUE_APP_TRANSLATOR_API_KEY,
          "Ocp-Apim-Subscription-Region": process.env.VUE_APP_TRANSLATOR_REGION,
          "Content-type": "application/json",
          "X-ClientTraceId": UUIDGenerator()
        },
        params: {
          "api-version": "3.0",
          "from": "en",
          "to": `${locale}`,
          "textType": "html"
        },
        data: dataToTranslate,
        responseType: "json"
      }).then((response) => {
        
          const translatedJson = {}
          let index = 0
          for (const key of Object.keys(itemsHash)) {
            
            if (translateAll) {

              if (itemsHash[key][defaultLocale] !== undefined){

                const item = response.data[index];
                translatedJson[key] = replaceAll(item.translations[0].text, keys);
                index++
              }
            } else {
              if (itemsHash[key][defaultLocale] !== undefined && (itemsHash[key][locale] === undefined || !itemsHash[key][locale])){
      
                const item = response.data[index];
                translatedJson[key] = replaceAll(item.translations[0].text, keys);
                index++
              }
            }
          }
          var translatedCategories = Object.keys(translatedJson).filter(v => v.startsWith('Categories.'))
          translatedCategories.forEach(key => {
            var re = /\.([^\.]+)\./
            if (project.content[re.exec(key)[1]] != undefined) project.content[re.exec(key)[1]].translation[locale] = translatedJson[key]
            delete translatedJson[key]
          })
          Object.entries(translatedJson).forEach((entry) => {
            const [key, value] = entry
            let item = itemsHash[key];
            if (!item) return;
            const row = [value, '']
            translationLoading.value = false
            updateItemWithStrings(item, row, [locale]);
          })
          updateQuestionnaire(project)

        
      }).catch(function (error) {
        console.log("Error to translate: " + locale + ". Error: " + JSON.stringify(error, null, 2));
      });
    }

    
    const isObjectEmpty = (objectName) => {
      return (
          objectName &&
          Object.keys(objectName).length === 0 &&
          objectName.constructor === Object
      )
  }


    return {
        surveyTemplate,
        templateOptions,
        templateLoading,
        fetchTemplates,
        fetchTemplate,
        projectData,
        exportLoading,
        fetchProject,
        addProjectQuestionnaire,
        downloadQuestionnaire,
        updateQuestionnaire,
        deleteQuestionnaire,
        deleteQuestionnaireLoading,
        choicesOrder,
        getContentTooltip,
        textMapping,
        languageOptions,
        languageLoading,
        fetchLanguages,

        fetchVersions,
        versionsItems,
        versionsLoading,
        addQuestionnaireVersion,
        dlVersionLoading,
        dlWordVersionLoading,
        downloadQuestionnaireVersion,
        downloadWordQuestionnaireVersion,

        fetchQuestionnaires,
        questionnaireLoading,
        questionnaireOptions,
        exportToCSVFile,
        importFromCSVFile,
        translateViaAI,
        translateWordViaAzureTranslator,
        translateViaAzureTranslator,
        translationLoading,
        questionnaireCategoryOptions,
        questionnaireCategoryLoading,
        questionnaireResearchMethodOptions,
        questionnaireResearchMethodLoading,
        fetchQuestionnaireResearchMethods,
        fetchQuestionnaireCategories,
        isObjectEmpty,

        fetchElements,
        elementLoading,
        elementOptions,
        variableSuggestions,
    }
}