<template>
    <v-dialog
        v-model="isDialogVisible"
        max-width="900"
        persistent
        v-if="localProjectData.content"
    >
        <v-card class="create-app-dialog">
            <v-card-text class="pa-5 pa-sm-10">
                <h5 class="text-h5 text-center mb-3">
                    Project Information
                </h5>
                <p class="text-sm text-center mb-6">
                    {{ localProjectData.service_type }} - {{ localProjectData.phase }} phases - N={{ localProjectData.sample }}
                </p>
                <v-row>
                    <v-col
                        cols="12"
                        sm="5"
                        md="4"
                        lg="3"
                    >
                        <app-stepper
                            :current-step.sync="currentStep"
                            :is-active-step-valid="isCurrentStepValid"
                            direction="vertical"
                            :items="createElement"
                            icon-size="24"
                            class="stepper-icon-step-bg"
                        >

                        </app-stepper>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="7"
                        md="8"
                        lg="9"
                    >
                        <v-window
                            v-model="currentStep"
                            class="disable-tab-transition stepper-content pt-2"
                        >
                            <v-window-item>
                                
                                <v-form
                                    :ref="`step0Form`"
                                >
                                    <h6 class="text-h6">
                                        Default Language & Translations
                                    </h6>
                                    <div class="mb-4">
                                        <span>Default language is used for the programming side of Decipher.</span>
                                    </div>
                                
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                        
                                        <v-autocomplete
                                            v-model="localProjectData.language"
                                            :items="availableLanguageOptions"
                                            :loading="languageLoading"
                                            item-text="name"
                                            item-value="id"
                                            label="Default Language"
                                            hide-detail
                                            dense
                                            outlined
                                            placeholder="Language for programming"
                                            :rules="[validators.required]"
                                            return-object
                                            @change="defaultLanguageUpdated"
                                        >
                                            
                                        </v-autocomplete>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-autocomplete
                                                v-model="localProjectData.translations"
                                                :items="availableTranslationOptions"
                                                :loading="languageLoading"
                                                item-text="name"
                                                item-value="code"
                                                label="Translations"
                                                hide-detail
                                                dense
                                                outlined
                                                multiple
                                                chips
                                                deletable-chips
                                                hint=""
                                                return-object
                                                @change="translationUpdated"
                                            >
                                                
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-window-item>
                            <v-window-item>
                                <v-form
                                    :ref="`step1Form`"
                                    v-if="localProjectData.language"
                                >
                                <div class="mb-1">
                                    <span></span>
                                </div>
                                <v-alert
                                    class="py-1 px-2 text-sm"
                                    outlined
                                    type="error"
                                >
                                    Categories listed are based on the default language selected in the previous page.
                                    If a new category needs to be added, please enter it in <strong>{{ localProjectData.language.name }}</strong>
                                </v-alert>
                                <v-row dense>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <!-- <v-text-field
                                            v-model="localProjectData.content.testCat"
                                            label="Which test category name would you like to appear in your survey​"
                                            outlined
                                            dense
                                            
                                            hint="This will be shown in the questions such as purchase frequency and brand used."
                                            hide-details="auto"
                                            :rules="[validators.required]"
                                        ></v-text-field> -->
                                        
                                        <v-combobox
                                                v-model="localProjectData.content.testCat"
                                                :items="categoriesPerLangList"
                                                :loading="questionnaireCategoryLoading"
                                                hide-selected
                                                :item-text="item => item.translation[localProjectData.language.code]"
                                                label="Which test category name would you like to appear in your survey"
                                                hint="This will be shown in the questions such as purchase frequency and brand used."
                                                dense
                                                outlined
                                                :rules="[validators.required]"
                                                hide-details="auto"
                                                return-object
                                                @change="OnChangeCategory($event, 'testCat')"
                                                >
                                                
                                                <template v-slot:selection="data" >
                                                    <!-- HTML that describe how select should render selected items -->
                                                    <template v-if="(typeof data.item == 'object')">
                                                    {{ data.item.translation[localProjectData.language.code] }}
                                                    </template>
                                                    <template v-else>
                                                        {{ data.item }}
                                                    </template>
                                                </template>
                                                <template v-slot:item="data">
                                                    <!-- HTML that describe how select should render selected items -->
                                                    {{ data.item.translation[localProjectData.language.code] }}
                                                </template>
                                                <template v-slot:no-data>
                                                    <div  class="d-flex align-center">
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                        <v-list-item-title>
                                                            No results matching. Press <kbd>enter</kbd> to create a new category
                                                        </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    </div>
                                                </template>
                                            </v-combobox>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-text-field
                                            v-model="localProjectData.content.brand"
                                            label="Brand name tested?"
                                            outlined
                                            dense
                                            
                                            hint="This will be shown as the brand name in the questionnaire."
                                            hide-details="auto"
                                            :rules="[validators.required]"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-autocomplete 
                                            v-model="localProjectData.research_method"
                                            :items="questionnaireResearchMethodOptions"
                                            :loading="questionnaireResearchMethodLoading"
                                            item-text="name"
                                            item-value="id"
                                            label="Research Method"
                                            outlined
                                            dense
                                            hide-details="auto"
                                            :rules="[validators.required]"
                                        >

                                        </v-autocomplete>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-text-field
                                            v-model="localProjectData.content.usageTime"
                                            label="Past usage in months"
                                            outlined
                                            dense
                                            
                                            type="number"
                                            hint="Brand purchase in the past X months"
                                            hide-details="auto"
                                            :rules="[validators.required]"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-text-field
                                            v-model="localProjectData.content.cellCount"
                                            label="Set the number of cells in your questionnaire"
                                            outlined
                                            dense
                                            type="number"
                                            hint="2 if Current/Test, 4 if Current Buyers, Current Non-Buyers, Test Buyers and Test Non-Buyers"
                                            hide-details="auto"
                                            @change="setCellsCount"
                                            :rules="[validators.required, minRule(localProjectData.content.cellCount, localProjectData.phase)]"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-divider class="mt-4 mb-2"></v-divider>
                                <div class="mb-2">
                                    <span>Select clutter categories for screening questions</span>
                                    <v-btn
                                        text
                                        icon
                                        small
                                        color="primary"
                                        @click="randomClutter"
                                        
                                    >
                                    <v-icon 
                                        color="primary"
                                        
                                    >{{ icons.mdiShuffleVariant  }}</v-icon>
                                    </v-btn>
                                </div>
                                <v-row
                                    dense
                                >
                                <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-combobox
                                            v-model="localProjectData.content.clutterCat1"
                                            :items="categoriesPerLangList"
                                            :loading="questionnaireCategoryLoading"
                                            :item-text="item => item.translation[localProjectData.language.code]"
                                            hide-selected
                                            label="Clutter Cat. 1"
                                            dense
                                            outlined
                                            :rules="[validators.required]"
                                            hide-details="auto"
                                            return-object
                                            @change="OnChangeCategory($event, 'clutterCat1')"
                                            >
                                                
                                                <template v-slot:selection="data" >
                                                    <!-- HTML that describe how select should render selected items -->
                                                    <template v-if="(typeof data.item == 'object')">
                                                    {{ data.item.translation[localProjectData.language.code] }}
                                                    </template>
                                                    <template v-else>
                                                        {{ data.item }}
                                                    </template>
                                                </template>
                                                <template v-slot:item="data">
                                                    <!-- HTML that describe how select should render selected items -->
                                                    {{ data.item.translation[localProjectData.language.code] }}
                                                </template>
                                            <template v-slot:no-data>
                                                <div  class="d-flex align-center">
                                                <v-list-item>
                                                    <v-list-item-content>
                                                    <v-list-item-title>
                                                        No results matching. Press <kbd>enter</kbd> to create a new category
                                                    </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                </div>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-combobox
                                            v-model="localProjectData.content.clutterCat2"
                                            :items="categoriesPerLangList"
                                            :loading="questionnaireCategoryLoading"
                                            :item-text="item => item.translation[localProjectData.language.code]"
                                            hide-selected
                                            label="Clutter Cat. 2"
                                            dense
                                            outlined
                                            :rules="[validators.required]"
                                            hide-details="auto"
                                            return-object
                                            @change="OnChangeCategory($event, 'clutterCat2')"
                                            >
                                                
                                                <template v-slot:selection="data" >
                                                    <!-- HTML that describe how select should render selected items -->
                                                    <template v-if="(typeof data.item == 'object')">
                                                    {{ data.item.translation[localProjectData.language.code] }}
                                                    </template>
                                                    <template v-else>
                                                        {{ data.item }}
                                                    </template>
                                                </template>
                                                <template v-slot:item="data">
                                                    <!-- HTML that describe how select should render selected items -->
                                                    {{ data.item.translation[localProjectData.language.code] }}
                                                </template>
                                            <template v-slot:no-data>
                                                <div  class="d-flex align-center">
                                                <v-list-item>
                                                    <v-list-item-content>
                                                    <v-list-item-title>
                                                        No results matching. Press <kbd>enter</kbd> to create a new category
                                                    </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                </div>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-combobox
                                            v-model="localProjectData.content.clutterCat3"
                                            :items="categoriesPerLangList"
                                            :loading="questionnaireCategoryLoading"
                                            :item-text="item => item.translation[localProjectData.language.code]"
                                            hide-selected
                                            label="Clutter Cat. 3"
                                            dense
                                            outlined
                                            :rules="[validators.required]"
                                            hide-details="auto"
                                            return-object
                                            @change="OnChangeCategory($event, 'clutterCat3')"
                                            >
                                                
                                                
                                            <template v-slot:selection="data" >
                                                    <!-- HTML that describe how select should render selected items -->
                                                    <template v-if="(typeof data.item == 'object')">
                                                    {{ data.item.translation[localProjectData.language.code] }}
                                                    </template>
                                                    <template v-else>
                                                        {{ data.item }}
                                                    </template>
                                                </template>
                                                <template v-slot:item="data">
                                                    <!-- HTML that describe how select should render selected items -->
                                                    {{ data.item.translation[localProjectData.language.code] }}
                                                </template>
                                            <template v-slot:no-data>
                                                <div  class="d-flex align-center">
                                                <v-list-item>
                                                    <v-list-item-content>
                                                    <v-list-item-title>
                                                        No results matching. Press <kbd>enter</kbd> to create a new category
                                                    </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                </div>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-combobox
                                            v-model="localProjectData.content.clutterCat4"
                                            :items="categoriesPerLangList"
                                            :loading="questionnaireCategoryLoading"
                                            :item-text="item => item.translation[localProjectData.language.code]"
                                            hide-selected
                                            label="Clutter Cat. 4"
                                            dense
                                            outlined
                                            :rules="[validators.required]"
                                            hide-details="auto"
                                            return-object
                                            @change="OnChangeCategory($event, 'clutterCat4')"
                                            >
                                                
                                                
                                            <template v-slot:selection="data" >
                                                    <!-- HTML that describe how select should render selected items -->
                                                    <template v-if="(typeof data.item == 'object')">
                                                    {{ data.item.translation[localProjectData.language.code] }}
                                                    </template>
                                                    <template v-else>
                                                        {{ data.item }}
                                                    </template>
                                                </template>
                                                <template v-slot:item="data">
                                                    <!-- HTML that describe how select should render selected items -->
                                                    {{ data.item.translation[localProjectData.language.code] }}
                                                </template>
                                            <template v-slot:no-data>
                                                <div  class="d-flex align-center">
                                                <v-list-item>
                                                    <v-list-item-content>
                                                    <v-list-item-title>
                                                        No results matching. Press <kbd>enter</kbd> to create a new category
                                                    </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                </div>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                                <v-divider class="mt-4 mb-2"></v-divider>
                                <!-- <div class="mb-2">
                                    <span>Select common brands for past purchase questions</span>
                                    <v-tooltip
                                        bottom
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                text
                                                icon
                                                small
                                                color="primary"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                            <v-icon 
                                                color="primary"
                                                
                                            >{{ icons.mdiShimmer  }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ask ChatGPT for the top 10 brands in test category</span>
                                    </v-tooltip>
                                    
                                </div> -->
                                <v-row
                                    dense
                                >
                                <span></span>
                                </v-row>
                                
                                
                            </v-form>
                            </v-window-item>
                            
                            <v-window-item>
                                
                                <v-form
                                    :ref="`step2Form`"
                                >
                                    <v-row dense>
                                        <v-col
                                            cols="12"
                                            sm="4"
                                        >
                                            <span>Sample split by cell.</span>
                                            
                                            <span class="error--text text-xs">{{sampleRules}}</span>
                                            <v-btn
                                                text
                                                icon
                                                small
                                                color="primary"
                                                @click="evenCellSplit('sample_split')"
                                            >
                                            <v-icon 
                                                color="primary"
                                                
                                            >{{ icons.mdiSetSplit }}</v-icon>
                                            </v-btn>
                                            <div class="pt-2">
                                                <v-text-field
                                                    v-for="(sample, index) in localProjectData.content.sample_split" :key="index"
                                                    v-model="localProjectData.content.sample_split[index]"
                                                    :label="`Cell ${index+1}`"
                                                    outlined
                                                    dense
                                                    
                                                    type="number"
                                                    class="pb-2"
                                                    hide-details
                                                    :rules="[validators.required]"
                                                ></v-text-field>
                                            </div>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="4"
                                        >
                                            <span>Sample doing flash exposure by cell</span>
                                            <v-btn
                                                text
                                                icon
                                                small
                                                color="primary"
                                                @click="evenCellSplit('sample_flash')"
                                            >
                                            <v-icon 
                                                color="primary"
                                                
                                            >{{ icons.mdiSetSplit }}</v-icon>
                                            </v-btn>
                                            <div class="pt-2">
                                                <v-text-field
                                                    v-for="(sample, index) in localProjectData.content.sample_flash" :key="index"
                                                    v-model="localProjectData.content.sample_flash[index]"
                                                    :label="`Cell ${index+1}`"
                                                    outlined
                                                    dense
                                                    type="number"
                                                    hide-details
                                                    class="pb-2"
                                                ></v-text-field>
                                            </div>
                                        </v-col>
                                        
                                        <v-col
                                            cols="12"
                                            sm="4"
                                        >
                                            <span>Sample doing Eyeflow by cell</span>
                                            <v-btn
                                                text
                                                icon
                                                small
                                                color="primary"
                                                @click="evenCellSplit('sample_ef')"
                                            >
                                            <v-icon 
                                                color="primary"
                                                
                                            >{{ icons.mdiSetSplit }}</v-icon>
                                            </v-btn>
                                            <div class="pt-2">
                                                <v-text-field
                                                    v-for="(sample, index) in localProjectData.content.sample_ef" :key="index"
                                                    v-model="localProjectData.content.sample_ef[index]"
                                                    :label="`Cell ${index+1}`"
                                                    outlined
                                                    dense
                                                    type="number"
                                                    hide-details
                                                    class="pb-2"
                                                ></v-text-field>
                                            </div>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="4"
                                        >
                                            <span>Sample doing findability exercise by cell</span>
                                            <v-btn
                                                text
                                                icon
                                                small
                                                color="primary"
                                                @click="evenCellSplit('sample_findability')"
                                            >
                                            <v-icon 
                                                color="primary"
                                                
                                            >{{ icons.mdiSetSplit }}</v-icon>
                                            </v-btn>
                                            <div class="pt-2">
                                                <v-text-field
                                                    v-for="(sample, index) in localProjectData.content.sample_findability" :key="index"
                                                    v-model="localProjectData.content.sample_findability[index]"
                                                    :label="`Cell ${index+1}`"
                                                    outlined
                                                    dense
                                                    type="number"
                                                    hide-details
                                                    class="pb-2"
                                                ></v-text-field>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-window-item>
                            <v-window-item>
                                
                                <v-form
                                    :ref="`step3Form`"
                                >
                                    <h6 class="text-h6 mb-4">
                                        Propensity to buy, Findability & Comm2  items
                                    </h6>
                                    <v-row dense><v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <span>Specify name for each cell</span>
                                            <v-btn
                                                text
                                                icon
                                                small
                                                color="primary"
                                                @click="evenCellSplit('phases')"
                                            >
                                            <v-icon 
                                                color="primary"
                                                
                                            >{{ icons.mdiSetSplit }}</v-icon>
                                            </v-btn>
                                            <v-text-field
                                                v-for="(phase, index) in localProjectData.content.phases" :key="index"
                                                v-model="localProjectData.content.phases[index]"
                                                :label="`${index == 0 ? 'Current' : 'Test'}`"
                                                outlined
                                                dense
                                                class="pb-2"
                                                hide-details
                                                :rules="[validators.required]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <span>Specify name of test product for piping purposes</span>
                                            <v-btn
                                                text
                                                icon
                                                small
                                                color="primary"
                                                @click="evenCellSplit('testProduct')"
                                            >
                                            <v-icon 
                                                color="primary"
                                                
                                            >{{ icons.mdiSetSplit }}</v-icon>
                                            </v-btn>
                                            <v-text-field
                                                v-for="(testProduct, index) in localProjectData.content.testProduct" :key="index"
                                                v-model="localProjectData.content.testProduct[index]"
                                                :label="`Cell ${index+1}`"
                                                outlined
                                                dense
                                                class="pb-2"
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <span>Specify name of competitor product for Comm2 & more</span>
                                            <v-btn
                                                text
                                                icon
                                                small
                                                color="primary"
                                                @click="evenCellSplit('competitor')"
                                            >
                                            <v-icon 
                                                color="primary"
                                                
                                            >{{ icons.mdiSetSplit }}</v-icon>
                                            </v-btn>
                                            <v-text-field
                                                v-for="(comp, index) in localProjectData.content.competitor" :key="index"
                                                v-model="localProjectData.content.competitor[index]"
                                                :label="`Cell ${index+1}`"
                                                outlined
                                                dense
                                                class="pb-2"
                                                hide-details
                                            ></v-text-field>
                                        </v-col>

                                        
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            v-for="(findaPrefix, findaIndex) in Object.keys(localProjectData.content).filter((element) => element.startsWith('finda'))" :key="findaIndex"
                                        >
                                            <span>Specify product name for the {{ stringifyNumber(findaIndex+1)}} findability exercise</span>
                                            <v-btn
                                                text
                                                icon
                                                small
                                                color="primary"
                                                @click="evenCellSplit(findaPrefix)"
                                            >
                                            <v-icon 
                                                color="primary"
                                                
                                            >{{ icons.mdiSetSplit }}</v-icon>
                                            </v-btn>
                                            <v-text-field
                                                v-for="(finda, index) in localProjectData.content[findaPrefix]" :key="index"
                                                v-model="localProjectData.content[findaPrefix][index]"
                                                :label="`Cell ${index+1}`"
                                                outlined
                                                dense
                                                class="pb-2"
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                        
                                    </v-row>
                                        
                                    <v-row dense>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <span>Specify price of the test product for Propensity incl. currency</span>
                                            <v-btn
                                                text
                                                icon
                                                small
                                                color="primary"
                                                @click="evenCellSplit('price')"
                                            >
                                            <v-icon 
                                                color="primary"
                                                
                                            >{{ icons.mdiSetSplit }}</v-icon>
                                            </v-btn>
                                            <v-text-field
                                                v-for="(price, index) in localProjectData.content.price" :key="index"
                                                v-model="localProjectData.content.price[index]"
                                                :label="`Cell ${index+1}`"
                                                outlined
                                                dense
                                                class="pb-2"
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                        
                                        
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            v-if="localProjectData.methodology == 'innocompass'"
                                        >
                                            <v-switch
                                                v-model="localProjectData.content.ad"
                                                label="Ad/No Ad"
                                                hide-details
                                                v-if="containsKey('ad')"
                                            >

                                            </v-switch>
                                            <v-switch
                                                v-model="localProjectData.content.concept"
                                                label="Concept/No Concept"
                                                hide-details
                                                v-if="containsKey('concept')"
                                            >

                                            </v-switch>
                                        </v-col>
                                        
                                        
                                    </v-row>
                                </v-form>
                            </v-window-item>
                        </v-window>

                        <div class="d-flex mt-8">
                            <v-btn
                                outlined
                                color="warning"
                                @click="cancel"
                            >
                                Cancel
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                class="mr-2"
                                :disabled="currentStep === 0"
                                @click="currentStep--"
                            >
                                <v-icon
                                start
                                class="flip-in-rtl"
                                >{{ icons.mdiArrowLeft }}</v-icon>
                            </v-btn>

                            <v-btn
                                v-if="createElement.length - 1 === currentStep"
                                outlined
                                color="success"
                                @click="onSubmit"
                            >
                                Save
                            </v-btn>

                            <v-btn
                                v-else
                                @click="checkStepValidation(currentStep+1)"
                            >

                                <v-icon
                                end
                                class="flip-in-rtl"
                                >{{ icons.mdiArrowRight }}</v-icon>
                            </v-btn>
                            </div>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>

    </v-dialog>
    
</template>

<script>
import { ref, watch, computed, getCurrentInstance, onMounted } from '@vue/composition-api'
import {
    mdiClipboardOutline,
    mdiCubeOutline,
    mdiDatabaseOutline,
    mdiTranslate,
    mdiArrowLeft,
    mdiArrowRight,
    mdiTrashCanOutline,
    mdiPlus,
    mdiSetSplit,
    mdiShuffleVariant,
    mdiShimmer
} from '@mdi/js'
import { required, requiredArray, between } from '@core/utils/validation'
import useSurvey from '../useSurvey'

import AppStepper from '@core/components/AppStepper.vue'

export default ({
    components: {
        AppStepper
    },
    props: {
        isDialogVisible: {
            type: Boolean,
            required: true,
        },
        projectData: {
            type: Object,
            required: true
        },
        languageOptions: {
            type: Array,
            required: true
        },
        languageLoading: {
            type: Boolean,
            required: true
        }
    },
    setup(props, {emit}) {

        const vm = getCurrentInstance().proxy
        const currentStep = ref(0)
        const localProjectData = ref({})
        

        const isCurrentStepValid = ref(true)

        const resetProjectData = () => {
            localProjectData.value = JSON.parse(JSON.stringify(props.projectData))
            clutter1.value = localProjectData.value.content.clutterCat1
            clutter2.value = localProjectData.value.content.clutterCat2
            clutter3.value = localProjectData.value.content.clutterCat3
            clutter4.value = localProjectData.value.content.clutterCat4
            testCat.value = localProjectData.value.content.testCat
            currentStep.value = 0

            if(localProjectData.value.content.sample_ef == undefined){
                localProjectData.value.content.sample_ef = []
                for (let i = 0; i < localProjectData.value.cellCount; i++){
                    localProjectData.value.content.sample_ef.push(0)
                }
            }

        }
        const createElement = [
            {
                icon: mdiTranslate,
                title: 'Language',
                subtitle: 'Translation',
            },
            {
                icon: mdiClipboardOutline,
                title: 'General Input',
                subtitle: 'Test Category, clutters, etc.',
            },
            {
                icon: mdiDatabaseOutline,
                title: 'Sampling Info.',
                subtitle: 'Sample Size',
            },
            {
                icon: mdiCubeOutline,
                title: 'Final Touches',
                subtitle: 'Propensity, Comm2, etc.',
            },
        ]

        const {
            questionnaireCategoryLoading,
            questionnaireCategoryOptions,
            questionnaireResearchMethodLoading,
            questionnaireResearchMethodOptions,
            fetchQuestionnaireResearchMethods,
            fetchQuestionnaireCategories,
            translateWordViaAzureTranslator,
            addQuestionnaireCategory
        } = useSurvey()

        const clutter1Search = ref('')
        const clutter2Search = ref('')
        const clutter3Search = ref('')
        const clutter4Search = ref('')
        const testCatSearch = ref('')

        const clutter1 = ref('')
        const clutter2 = ref('')
        const clutter3 = ref('')
        const clutter4 = ref('')
        const testCat = ref('')

        const num_special = ['zeroth','first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
        const num_deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

        const stringifyNumber = (num) => {
            if (num < 20) return num_special[num];
            if (num%10 === 0) return num_deca[Math.floor(num/10)-2] + 'ieth';
            return num_deca[Math.floor(num/10)-2] + 'y-' + num_special[num%10];
        }

        const setCellsCount = () => {
            const finda = Object.keys(localProjectData.value.content).filter((element) => element.startsWith('finda'))
            
            localProjectData.value.content.cellCount = parseInt(localProjectData.value.content.cellCount)
            const length = localProjectData.value.content.sample_split.length
            if (length < localProjectData.value.content.cellCount){
                for (let i = 0; i < localProjectData.value.content.cellCount-length; i++){
                    localProjectData.value.content.sample_split.push(0)
                    if (localProjectData.value.content.sample_flash.length < localProjectData.value.content.cellCount) localProjectData.value.content.sample_flash.push(0)
                    if (localProjectData.value.content.sample_ef.length < localProjectData.value.content.cellCount) localProjectData.value.content.sample_ef.push(0)
                    if (localProjectData.value.content.sample_findability.length < localProjectData.value.content.cellCount) localProjectData.value.content.sample_findability.push(0)
                    if (localProjectData.value.content.price.length < localProjectData.value.content.cellCount) localProjectData.value.content.price.push(null)
                    if (localProjectData.value.content.competitor.length < localProjectData.value.content.cellCount) localProjectData.value.content.competitor.push(null)
                    if (localProjectData.value.content.testProduct.length < localProjectData.value.content.cellCount) localProjectData.value.content.testProduct.push(null)
                    
                    if (localProjectData.value.content.phases.length < localProjectData.value.content.cellCount) localProjectData.value.content.phases.push(null)
                    finda.forEach(findaLabel => {
                        if (localProjectData.value.content[findaLabel].length < localProjectData.value.content.cellCount) localProjectData.value.content[findaLabel].push(null)
                    })
                }
            } else {
                for (let i = 0; i < length - localProjectData.value.content.cellCount; i++){
                    if (localProjectData.value.content.sample_split.length > localProjectData.value.content.cellCount) localProjectData.value.content.sample_split.pop()
                    if (localProjectData.value.content.sample_flash.length > localProjectData.value.content.cellCount) localProjectData.value.content.sample_flash.pop()
                    if (localProjectData.value.content.sample_ef.length > localProjectData.value.content.cellCount) localProjectData.value.content.sample_ef.pop()
                    if (localProjectData.value.content.sample_findability.length > localProjectData.value.content.cellCount) localProjectData.value.content.sample_findability.pop()
                    if (localProjectData.value.content.price.length > localProjectData.value.content.cellCount) localProjectData.value.content.price.pop()
                    if (localProjectData.value.content.competitor.length > localProjectData.value.content.cellCount) localProjectData.value.content.competitor.pop()
                    if (localProjectData.value.content.testProduct.length > localProjectData.value.content.cellCount) localProjectData.value.content.testProduct.pop()
                    if (localProjectData.value.content.phases.length > localProjectData.value.content.cellCount) localProjectData.value.content.phases.pop()
                    finda.forEach(findaLabel => {
                        if (localProjectData.value.content[findaLabel].length > localProjectData.value.content.cellCount) localProjectData.value.content[findaLabel].pop()
                    })
                }
            }
        }
        
        const minRule = (value, min) => {
            return value => ( value && value >= min ) || `Cell count should be more or equal to ${min}`
        }
        
        const evenCellSplit = (param=null) => {
            if (param && localProjectData.value.content[param]) {
                let value = null

                if (localProjectData.value.content[param][0] == 0){
                    value = Math.floor(props.projectData.sample/props.projectData.content.cellCount)
                } else {
                    value = localProjectData.value.content[param][0]
                }
                localProjectData.value.content[param] = []
                for (let i = 0; i < localProjectData.value.content.cellCount; i++){
                    localProjectData.value.content[param][i] = value
                }
            }

        }
        
        const sumSample = computed(() => {
            if (localProjectData.value.content.sample_split){
                let total_sample = localProjectData.value.content.sample_split.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0);
                
                return localProjectData.value.sample - total_sample
            }
        })

        const sampleRules = computed(() => { 
            if (sumSample.value !== 0){
                return `Total sample size should be ${localProjectData.value.sample}. ${sumSample.value < 0 ? 'Over '+ (-sumSample.value) : 'Missing '+sumSample.value}`
            }
            return ''
        })

        const availableLanguageOptions = computed(() => {
            if (props.languageOptions) {
            return props.languageOptions.filter(language => language.programming == true)
            } else {
                return props.languageOptions
            }
        })

        
        const availableTranslationOptions = computed(() => {
            if (localProjectData.value.language) {
                return props.languageOptions.filter(language => {
                    return language.id != localProjectData.value.language.id
                })
            } else {
                return props.languageOptions
            }
        })


        const checkStepValidation = async (step) => {
            
            let formRef = `step${currentStep.value}Form`
            // Get Form templateRef of Step from argument
            const templateRef = (() => {
                //Check Ref is array format or not. When dynamically adding ref in v-for loop it creates an array instead of instance
                if (currentStep.value >= 0) return Array.isArray(vm.$refs[formRef]) ? vm.$refs[formRef][0] : vm.$refs[formRef]
                
                return null
            })()
            
            // If no validate step templateRef is found just return
            if (!templateRef) return

            // If step is valid proceed to the next step
            const isStepValid = templateRef.validate()
            if (isStepValid) {
                currentStep.value = step
                isCurrentStepValid.value = true
            } else {
                
                isCurrentStepValid.value = false
            }
        }

        const onSubmit = () => {
            checkStepValidation(currentStep.value)
            if(isCurrentStepValid.value){
                currentStep.value = 0
                emit('save', localProjectData)
            }
        }

        const activeCardClasses = computed(() => index => index === currentElementIndex.value ? 'active' : '')

        const cancel = () => {
                resetProjectData()
                emit('update:is-dialog-visible', false)
        }

        const translationUpdated = () => {
            localProjectData.value.translationUpdated = true
        }

        const defaultLanguageUpdated = (item) => {
            localProjectData.value.translations = localProjectData.value.translations.filter(lang => lang.id != item.id)
        }

        const randomClutter = () => {
            // Shuffle array
            const shuffled = questionnaireCategoryOptions.value.sort(() => 0.5 - Math.random());

            // Get sub-array of first n elements after shuffled
            let selected = shuffled.slice(0, 4);
            selected.forEach((element, index) => {
                const clutter = `clutterCat${index+1}`
                localProjectData.value.content[clutter] = element
            })
        }

        const categoriesPerLangList = computed(() => {
            return questionnaireCategoryOptions.value.sort((cat1, cat2) => {
                const nameA = cat1.translation[localProjectData.value.language.code].toUpperCase(); // ignore upper and lowercase
                const nameB = cat2.translation[localProjectData.value.language.code].toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                    return 1;
                }
                if (nameA < nameB) {
                    return -1;
                }

                // names must be equal
                return 0;
            })
        })

        const OnChangeCategory = async (category, clutter) => {

            if (typeof category == 'string') {
                const from = localProjectData.value.language.code
                const languages = [...["zh", "it", "de", "fr", "en"].filter(lang => lang !== from), ...localProjectData.value.translations.map(locale => locale.code )]
                // const to = from === 'en' ? 'fr' : 'en'
                const to = [...new Set(languages)]
                
                const translation = await translateWordViaAzureTranslator(category, from, to)
                if (translation.length > 0){
                    var newItem = {
                        name: category,
                        translation: {
                            [`${from}`]: category
                        }
                    }
                    translation[0].translations.map(translated => {
                        newItem['translation'][translated.to.slice(0, 2)] = translated.text
                    })
                    questionnaireCategoryOptions.value.push(newItem)
                    localProjectData.value.content[clutter] = newItem
                } else {
                    localProjectData.value.content[clutter] = category
                }
            }

        }

        const containsKey = (key ) => Object.keys(localProjectData.value.content).includes(key)

        
        watch(
            () => props.projectData, 
            () => {
                resetProjectData()
            }
        )

        onMounted(() => {
            fetchQuestionnaireCategories()
            fetchQuestionnaireResearchMethods()
        })

        // watch(
        //     () => localProjectData.value.translations,
        //     (newVal, oldVal) => {
        //         if(oldVal !== undefined){
        //             localProjectData.value.translationUpdated = true
        //             localProjectData.value.languages = languageOptions.value.filter(language => {
        //                 return language.id == localProjectData.value.language
        //             })
        //             localProjectData.value.languages.push(...newVal)
        //         }
        //     }
        // )

        // watch(
        //     () => localProjectData.value.language,
        //     (newVal, oldVal) => {
        //         if(oldVal !== undefined){
        //             localProjectData.value.translations = []
        //         localProjectData.value.languages = languageOptions.value.filter(language => {
        //             return language.id == newVal
        //         })
                
        //         }
        //     }
        // )
        
        return {
            currentStep,
            createElement,
            localProjectData,
            activeCardClasses,
            sampleRules,
            translationUpdated,
            defaultLanguageUpdated,
            availableLanguageOptions,
            availableTranslationOptions,
            OnChangeCategory,
            evenCellSplit,
            onSubmit,
            cancel,
            containsKey,
            checkStepValidation,
            isCurrentStepValid,
            clutter1Search,
            clutter2Search,
            clutter3Search,
            clutter4Search,
            testCatSearch,
            clutter1,
            clutter2,
            clutter3,
            clutter4,
            testCat,
            questionnaireCategoryLoading,
            questionnaireCategoryOptions,
            questionnaireResearchMethodLoading,
            questionnaireResearchMethodOptions,
            randomClutter,
            categoriesPerLangList,
            stringifyNumber,
            setCellsCount,
            minRule,

            icons: {
                mdiTrashCanOutline,
                mdiPlus,
                mdiArrowLeft,
                mdiArrowRight,
                mdiSetSplit,
                mdiShuffleVariant,
                mdiShimmer
            },

            validators: {
                required,
                between
            }
        }
    },
})
</script>


<style lang="scss">
.stepper-content .card-list {
  --v-card-list-gap: 24px;
}

.flexcol .v-btn__content {
  flex-direction: column;
}
.v-card .active {
  background: var(--v-primary-base);
  .v-card__title {
    color:white !important;
    .v-icon {
        color: white;
    }
  }
}

// .v-card--link:focus::before {
//   opacity: 0;
// }
</style>
